<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="assemblies"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="headline pb-0 pt-2">
            Assemblies
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="showForm"
            max-width="500"
          >
            <template #activator="{ on }">
              <v-btn
                color="shamrock"
                dark
                class="mb-2"
                elevation="0"
                v-on="on"
              >
                New Assembly
              </v-btn>
            </template>
            <AssemblyForm
              v-if="showForm"
              @created="handleCreatedAssembly"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <EditAndDeleteButtons
          :edit-href="`/admin/assemblies/${item._id}`"
          @delete="deleteAssembly(item._id)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import AssemblyForm from './AssemblyForm'
import { mapActions } from 'vuex'
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'

export default {
  name: 'AdminAssemblies',
  components: {
    AssemblyForm,
    EditAndDeleteButtons
  },
  data () {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'CC - Assembly Id',
          value: 'ccAssemblyId'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Category',
          value: 'category'
        },
        {
          text: 'Subcategory',
          value: 'suggestionsCategory'
        },
        {
          text: 'Notes',
          value: 'notes'
        },
        {
          text: 'Citations',
          value: 'citations'
        },
        {
          value: 'actions',
          sortable: false
        }
      ],
      assemblies: [],
      showForm: false
    }
  },
  created () {
    this.fetchAssemblies()
  },
  methods: {
    ...mapActions(['showSnackbar']),

    async fetchAssemblies () {
      const { data: assemblies } = await this.$axios.get('/assemblies')
      this.assemblies = assemblies.map(assembly => ({
        ...assembly
      }))
    },
    async deleteAssembly (id) {
      try {
        await this.$axios.delete(`/assemblies/${id}`)
        await this.fetchAssemblies()
      } catch (err) {
        await this.showSnackbar({
          color: 'error',
          message: 'Unable to delete assembly. Please try again.'
        })
        throw err
      }
    },
    handleCreatedAssembly () {
      this.showForm = false
      this.fetchAssemblies()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
